import axios from "axios";

export const actions = {
  async setPropertyConfiguration({commit}, payload) {
      await axios.post("/properties/update", payload,
        { headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }}
      );
  },
  async setDeviceConfiguration({commit}, payload) {
    await axios.post("/devices/update", payload,
      { headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }}
    );
  }  
};
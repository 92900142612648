export const getters = {
  getFlats(state) {
    return state.flats;
  },
  getCurrentFlushingConnection(state) {
    return state.currentFlushingProcessConnection;
  },
  getProperties(state) {
    return state.properties;
  },
};
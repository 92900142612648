export const getters = {
  getLinkedDevices(state) {
    return state.linkedDevices;
  },
  getUnlinkedDevices(state) {
    return state.unlinkedDevices;
  },
  getCurrentFunctionalTestsList(state) {
    return state.currentFunctionalTestsList;
  },
  getCurrentLastFlushProcess(state) {
    return state.currentLastFlushProcess;
  },
  getLogs(state) {
    return state.logs;
  },
  getCurrentTestingDevice(state) {
    return state.currentTestingDevice;
  },
  getCurrentProperty(state) {
    return state.currentProperty;
  }
};